import {UserService} from "../resources/services/UserService";
import {inject, NewInstance} from 'aurelia-dependency-injection';
import {TaskQueue} from "aurelia-framework";
import {Router} from "aurelia-router";
import {I18N} from "aurelia-i18n";
import {DialogMessages} from "resources/services/DialogMessages";
import {FhirService} from "../resources/services/FhirService";
import {ReportService} from "../resources/services/ReportService";
import {RuntimeInfo} from "../resources/classes/RuntimeInfo";
import {NitTools} from "../resources/classes/NursitTools";
import {Rule, validateTrigger, ValidationController, ValidationRules} from "aurelia-validation";
import {ISmileUser} from "./admin/interfaces/ISmileUser";
import {ConfigService} from "../resources/services/ConfigService";
import {bindable} from "aurelia-templating";
import * as environment from "../../config/environment.json";

@inject(UserService, DialogMessages, Router, I18N, NewInstance.of(ValidationController), TaskQueue)
export class Login {
    public static LoginRunning = false;
    get loggingIn() : boolean { return Login.LoginRunning; }
    set loggingIn(value : boolean ) { Login.LoginRunning = value; }

    @bindable username: string;
    @bindable password: string;
    isLoading = false;
    showLogin: boolean = true;
    @bindable showCreatePass: boolean;
    newPasswordInformation: NewPasswordInformation;
    g1: string = NitTools.UidName();
    g2: string = NitTools.UidName();
    loginForm: HTMLFormElement;
    inputNewPassword: HTMLInputElement;
    repeatNewPassword: HTMLInputElement;
    showFhirInfo: boolean;
    userAgent: string;
    inputPass: HTMLInputElement;
    inputUserName: HTMLInputElement;
    newPasswordButtonEnabled: boolean = true;
    showPassword : boolean = false;
    displayLogin : boolean = true;

    constructor(protected userService : UserService, protected dialogMessages : DialogMessages, protected router : Router,
                protected i18n: I18N, protected controller: ValidationController, protected taskQueue: TaskQueue)
    {
        this.newPasswordInformation = new NewPasswordInformation(this.i18n);
        this.controller?.addObject(this.newPasswordInformation, this.newPasswordInformation.rules);
        this.controller.validateTrigger = validateTrigger.changeOrBlur;

        this.displayLogin = window.location.href.indexOf('password=') === -1 &&
                            window.location.href.indexOf('user=') === -1
    }

    setNewPassword() {
        this.newPasswordButtonEnabled = true;
        this.controller.reset();
        this.controller.validate({object: this.newPasswordInformation, rules: this.newPasswordInformation.rules})
            .then(async result => {
                if (result.valid) {
                    RuntimeInfo.IsLoading = true;

                    this.newPasswordInformation.setPassword(this.newPasswordInformation.newPassword).enable().unlock();
                    if ((this.userService?.practitioner?.name?.length||0) > 0) {
                        this.newPasswordInformation.familyName(this.userService.practitioner.name[0].family).givenName(this.userService.practitioner.name[0].given?.join(', '));
                    }

                    let result = await this.userService.updateUser(this.newPasswordInformation.user);
                    RuntimeInfo.IsLoading = false;

                    if (!result.success) {
                        console.warn('When setting user password:', result.error);
                        this.dialogMessages.prompt(this.i18n.tr('error_setting_pass'), this.i18n.tr("information"), true);
                    } else {
                        this.password = this.newPasswordInformation.newPassword;
                        this.login();
                    }
                } else {
                    this.newPasswordButtonEnabled = false;
                    /* let str: string[] = [];
                    result.results.forEach(r => {
                        if (r.message) {
                            str.push(r.message);
                        }
                    }); */

                    this.dialogMessages.prompt(this.i18n.tr('could_not_set_password'), this.i18n.tr('information'), true)
                        .whenClosed(() => {
                            this.newPasswordButtonEnabled = true;
                        });
                }
            });
    }

    async showPasswordDialog() {
        RuntimeInfo.IsLoading = true;
        try {
            this.newPasswordInformation.userName = this.username;
            this.newPasswordInformation.user = await this.userService.loadRemoteUser(this.username);
            this.showLogin = false;
            this.showCreatePass = true;
            this.controller.reset({rules: this.newPasswordInformation.rules, object: this.newPasswordInformation});
            this.taskQueue.queueTask(() => this.inputNewPassword.focus());
        } catch (e) {
            this.dialogMessages.prompt(e.message || JSON.stringify(e), this.i18n.tr("error"), true);
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }

//#region GETTER
    get version(): string {
        return RuntimeInfo.AppVersion;
    }

    get isDebug(): boolean {
        return ConfigService.Debug;
    }

    get reportServer() {
        return ReportService.ReportServer;
    }

    get fhirServer() {
        return FhirService.Endpoint;
    }

    get fhirAdmin() {
        return FhirService.AdminEndpoint;
    }

    get offlineModeAviable(): boolean {
        return FhirService.OfflineClientSettings ? !FhirService.OfflineClientSettings.isOffline && FhirService.OfflineClientSettings.enabled : false;
    }

    private uaCheck: boolean = undefined;

    get offlineModeEnabled() {
        if (typeof this.uaCheck === "undefined") this.uaCheck = ConfigService.ParseUAString(navigator.userAgent).offLineSupported;
        return this.uaCheck && ConfigService.OfflineModeConfiguredAsEnabled;
    }

//#endregion

    async attached() {
        RuntimeInfo.HideInfo();
        // this.showFhirInfo = ConfigService.Debug === true;
        this.taskQueue.queueTask(() => {
            if (this.inputUserName && typeof this.inputUserName.focus === 'function')
                this.inputUserName.focus();
        });

        if (ConfigService.Debug)
            window["loginForm"] = this;
    }

    togglePass(parentDivId : string, forceHidden : boolean = false) {
        const div : HTMLDivElement = document.querySelector(`#${parentDivId}`);
        if (!div) return;

        const input : HTMLInputElement = div.querySelector('input');
        const button : HTMLAnchorElement = div.querySelector('a');

        if (!input || !button) return;

        let showingPassword = input.type === 'text';
        let showPassword = !showingPassword;

        if (forceHidden)
            showPassword = false;

        input.type = showPassword ? 'text' : 'password';

        const mdi = button.querySelector('.mdi');
        if (!mdi) return;

        mdi.classList.remove('mdi-visibility-off', 'mdi-visibility');
        mdi.classList.add(showPassword ? 'mdi-visibility' : 'mdi-visibility-off');

        const timerId = `${parentDivId}_timer`;
        if (showPassword)
            this[timerId] = window.setTimeout(() => this.togglePass(`${parentDivId}`, true), 30000);
        else
            window.clearTimeout(this[timerId]);
    }

    async activate() {
        if (await this.userService.checkLogin()) {
            this.router.navigate('encounter/');
            const params = NitTools.GetUrlParams();
            if (params.password) {
                this.password = params.password;
                if (params.user) {
                    this.username = params.user;

                    if (this.username && this.password) {
                        await this.login();
                    }
                }
            }
        }
    }

    userNewPasswordKeyPress($event) {
        if ($event.keyCode === 13) {
            this.repeatNewPassword.focus();
        }

        return true;
    }

    userRepeatPasswordKeyPress($event) {
        if ($event.keyCode === 13 && this.newPasswordButtonEnabled) {
            this.setNewPassword();
        }

        return true;
    }

    userPasswordKeyPress($event) {
        if ($event.keyCode === 13 && this.newPasswordButtonEnabled) {
            this.login();
        }

        return true;
    }

    userNameKeyPress($event) {
        if ($event.keyCode === 13) {
            this.inputPass.focus();
        }

        return true;
    }

    async login() : Promise<boolean> {
        let result;
        try {
            this.loggingIn = true;
            this.newPasswordButtonEnabled = false;
            if (!this.username) return;

            const uncasedUserName = this.username.trim();

            this.username = ConfigService.useUpperCaseUserName ? uncasedUserName.toUpperCase() : uncasedUserName;
            result = await this.userService.login(this.username, this.password);

            // well, maybe someone disabled useUpperCaseUserName but it is needed? Give it a try - what could it hurt?
            if (!result?.success && !ConfigService.useUpperCaseUserName) {
                this.username = uncasedUserName.toUpperCase();
                result = await this.userService.login(this.username, this.password);
            }

            if (result) {
                if (result.success === true) {
                    sessionStorage.setItem(environment.sessionName, btoa(`${this.username}:${this.password}`));                    
                    if (ConfigService.UseOAuth2) {
                        ConfigService.SetOAuthTokenProperties(result);
                        this.userService.checkOAuthLoginTimer();
                    }

                    await FhirService.GetFhirVersion();

                    let role = this.userService.getCurrentUserRole();

                    if (this.username.toUpperCase() === "ROOT" || role === "admin") {
                        this.router.navigate('admin');
                    } else {
                        let returnUrl = sessionStorage.getItem("returnUrl");
                        if (returnUrl) {
                            sessionStorage.removeItem(returnUrl);
                            window.location.href = returnUrl;
                        } else {
                            this.router.navigate('encounter');
                        }
                    }
                } else {
                    this.loggingIn = false;
                    if (result.needsToSetPassword) {
                        // this.router.navigate('setPassword');
                        await this.showPasswordDialog();
                    } else {
                        this.dialogMessages.prompt(result.error||result.message).whenClosed(() => {
                            this.newPasswordButtonEnabled = true;
                            this.inputUserName.focus();
                        }).catch(err => console.warn(err));

                        this.taskQueue.queueTask(() => {
                            window.setTimeout(() => {
                                let btn: HTMLButtonElement = document.querySelector("button.btn-default");
                                if (btn) btn.focus();
                            }, 250);
                        });
                    }
                }
            }
        } catch (e) {
            if (!ConfigService.IsTest) {
                this.dialogMessages.prompt(e, this.i18n.tr("information"), true)
                    .whenClosed(() => this.newPasswordButtonEnabled = true)
                    .catch(err => console.warn(err));
            } else {
                console.warn(e);
            }
            
            result.success = false;
            this.loggingIn = false;
        }

        return result.success;
    }
}

export class NewPasswordInformation {
    public setPassword(newPassword: string): NewPasswordInformation {
        if (this.user) {
            this.user.password = newPassword;
        }

        return this;
    }

    public enable(): NewPasswordInformation {
        this.user.accountDisabled = false;
        return this;
    }

    public unlock(): NewPasswordInformation {
        this.user.accountLocked = false;
        return this;
    }

    public familyName(name: string): NewPasswordInformation {
        this.user.familyName = name;
        return this;
    }

    public givenName(name: string): NewPasswordInformation {
        this.user.givenName = name;
        return this;
    }

    public user: ISmileUser;
    public newPassword: string;
    public confirmPassword: string;
    public userName: string;

    private i18n: I18N;

    public get rules(): Rule<any, any>[][] {
        if (!ConfigService.IsTest) {
            return ValidationRules
                // --- new password check
                .ensure('newPassword')
                .required().withMessage(this.i18n.tr('no_pass_entered'))
                .minLength(6).withMessage(this.i18n.tr('pass_too_short'))
                // --- confirm password check
                .ensure('confirmPassword')
                .required().withMessage(this.i18n.tr('pass_no_confirm'))
                .satisfies((cPass: string, obj: NewPasswordInformation) => {
                    return cPass === obj.newPassword;
                }).withMessage(this.i18n.tr('pass_no_match'))
                // ---  internal checks
                .ensure('userName').required().withMessage('No username provided')
                .ensure('user').required().withMessage('No user provided')
                // ---
                .rules;
        } else {
            return ValidationRules
                // --- new password check
                .ensure('newPassword')
                .required().withMessage("No Password entered")
                .minLength(6).withMessage("Password too short")
                // --- confirm password check
                .ensure('confirmPassword')
                .required().withMessage("Password not confirmed")
                .satisfies((cPass: string, obj: NewPasswordInformation) => {
                    return cPass === obj.newPassword;
                }).withMessage("Passwords do not match")
                // ---  internal checks
                .ensure('userName').required().withMessage('No username provided')
                .ensure('user').required().withMessage('No user provided')
                // ---
                .rules;
        }
    }

    constructor(i18n: I18N) {
        this.i18n = i18n;
    }
}
